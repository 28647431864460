import React, { useState, cloneElement, useEffect } from "react";
const TabComponent = ({ tabHeadings, activeId, children, location }) => {
  const [activeTab, setActiveTab] = useState(activeId);

  const renderChildren = (tag) => {
    const modifiedChildren = React.Children.map(children, (child) =>
    cloneElement(child, { tag })
  );
    return modifiedChildren
  }

  useEffect(() => {
    if(location.hash !== '')
      setActiveTab(location.hash.split('#')[1].split('/')[0])
  }, [location])
  return (
    <div>
      <div className="flex px-4 pb-4 justify-center">
        {tabHeadings.map((tab) => (
          <button
            key={tab.id}
            className={`px-5 py-2.5 text-lg font-medium text-center border-b-2 ${
              activeTab === tab.id
                ? "text-[#86B146] border-[#86B146]"
                : "border-white"
            } transition duration-300`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="mt-4">
        {tabHeadings.map((tab) => (
          <div
            key={tab.id}
            className={`px-4 pt-4 ${
              activeTab === tab.id ? "opacity-100" : "opacity-0 hidden"
            } transition-opacity duration-300 ease-in-out`}
            style={{ transitionProperty: "opacity" }}
          >
            {renderChildren(tab.tag)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TabComponent;
