import React from "react";
import {
  Container,
  Section,
  Seo,
} from "@components";
import { PageLayout } from "../../components/site/layout/page-layout";
import { ResourceGrid, ResourceProvider } from "../../components/site/resources"
import { ResourceBanner } from "../../components/site/product";
import TabComponent from "../../components/site/resources/Tab";

const tabHeadings = [
  {
    id: 'whitepapers',
    title: "Ebooks & Whitepapers",
    tag: "brief",
  },
  {
    id: 'videos',
    title: "Videos & Podcasts",
    tag: "video",
  }
];

const ResourcesPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      <ResourceBanner title="Resources" />

      <ResourceProvider>
        <Section>
          <Container className="scroll-mt-32 py-[6rem]">
            <TabComponent tabHeadings={tabHeadings} activeId={'whitepapers'} location={location} >
              <ResourceGrid />
            </TabComponent>
          </Container>
        </Section>
      </ResourceProvider>
    </PageLayout>
  );
};

export default ResourcesPage;
